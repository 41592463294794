.recently-viewed-block {
    padding-left: 9999px;
    padding-right: 9999px;
    margin-right: -9999px;
    margin-left: -9999px;


    .block {
        margin: 0;
        padding: 2.5rem 0;
    }

    .products-view-tile {
        /*margin: 0;*/
        .products-view-item {
            padding: 20px !important;
            border-width: 1px;
            border-style: solid;
            border-radius: 3px;
            height: 100%;
        }
    }
}
