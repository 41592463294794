@import "../../../../styles/functions/_util.scss";
@import "../../../../styles/functions/_list.scss";
@import "../../../../styles/functions/_prefixer.scss"; 
@import "../../../../styles/_settings.scss";

$btn-border-radius: rem-calc(4px) !default;
$btn-big: (padding (rem-calc(15px) rem-calc(40px) rem-calc(15px)), font-size rem-calc(16px), is-bold false) !default;
$btn-middle: (padding (rem-calc(13px) rem-calc(40px) rem-calc(13px)), font-size $font-size-xmedium, is-bold false) !default;
$btn-small: (padding (rem-calc(10px) rem-calc(20px) rem-calc(10px)), font-size $font-size-medium, is-bold false) !default;
$btn-xsmall: (padding (rem-calc(8px) rem-calc(20px) rem-calc(8px)), font-size $font-size-medium, is-bold false) !default;

@mixin btn-size($btn-size-name, $btn-size-param) {
    .#{$btn-size-name} {
        @if(list-find-value($btn-size-param, is-bold)) {
            font-weight: bold;
        }
        @else {
            font-weight: normal;
        }

        font-size: list-find-value($btn-size-param, font-size);
        line-height: list-find-value($btn-size-param, line-height);
        padding: list-find-value($btn-size-param, padding);
    }
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    /*vertical-align: middle;*/
    text-decoration: none;
    border-width: 0;
    text-align: center;
    border-radius: $btn-border-radius;
    line-height: 1.3; //обязательно указывать это свойство, иначе высота кнопок input и "a" разная
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
    /*&:focus {
        outline: none;
    }*/
}

.btn-block {
    display: block;
    width: 100%;
}

/*.btn-expander {
    width: 100%;
}*/

.btn--upper {
   text-transform: uppercase;
}

.btn[class^="icon-"]:before,
.btn[class^="icon-"]:after,
.btn[class*=" icon-"]:before,
.btn[class*=" icon-"]:after {
    font-size: $font-size-medium;
}

@include btn-size(btn-big, $btn-big);
@include btn-size(btn-middle, $btn-middle);
@include btn-size(btn-small, $btn-small);
@include btn-size(btn-xsmall, $btn-xsmall);

.btn-action {
    border-width:1px;
    border-style: solid;

}


.btn.products-view-buy {
    padding: 0.625rem 1.25rem 0.625rem;

    &:before {
        content: '';
        background-repeat: no-repeat;
        background-size: 24px;
        display: block;
        width: 23px;
        height: 23px;
        background-position: center;
        margin-right: 7px;
        -webkit-mask-image: url(../../images/cart.svg);
        mask-image: url(../../images/cart.svg);
        -webkit-mask-size: 21px;
        -webkit-mask-repeat: no-repeat;
    }
}


.catalog-filter-footer {

    & > .btn {
        font-weight: normal;
        font-size: 0.875rem;
        padding: 0.625rem 1.25rem 0.625rem;
    }
}