input.shipping-extend-input-hidden {
    opacity: 0;
    height: 0;
    width: 0;
    border: 0;
    display: block;
}

.shipping-item {
    .shipping-item-info {
        line-height: 1;

        .form-field-name {
            margin-bottom: 0;
        }
    }
}