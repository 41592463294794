/*.productCard,*/
.carousel-horizontal {
    .carousel-nav.carousel-nav-outside {
        bottom: 70%;
    }

    .carousel-nav {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 14px;

        .carousel-main-prev,
        .carousel-main-next,
        .full-arrow-in-circle {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 48px;
            width: 48px;
            text-align: center;
            border-radius: 100%;
            opacity: 0.4;


            &:hover {
                opacity: 1;
            }
        }

        .carousel-main-prev::after,
        .carousel-main-next::after,
        .full-arrow-in-circle::after {
            content: "";
            -webkit-mask-image: url(../../../../images/arrow.svg);
            mask-image: url(../../../../images/arrow.svg);
            font-size: 14px;
            width: 19px;
            height: 14px;
            display: block;
        }

        .carousel-main-prev::after,
        .full-arrow-in-circle.full-arrow-in-circle--prev::after {
            transform: rotate(180deg);
        }
    }
}

.checkout-thank-you-page-action,
.cart-popup-related-block,
.related-product-carousel-wrap,
.related-products {
    .carousel-horizontal:not(.carousel-colors) {
        & > .carousel-nav {

            .carousel-nav-prev::after {
                transform: rotate(180deg);
            }

            .carousel-nav-prev::after,
            .carousel-nav-next::after {
                content: "";
                -webkit-mask-image: url(../../../../images/arrow.svg);
                mask-image: url(../../../../images/arrow.svg);
                font-size: 14px;
                width: 19px;
                height: 14px;
                display: block;
            }
        }
    }
}


.carousel:not(.carousel-vertical) {

    .carousel-nav.carousel-nav-outside {
        position: absolute;
        right: 0;
        top: 35px;
        bottom: 0;
        display: flex;
        height: 180px;
        align-items: center;

        .carousel-nav-prev {
            font-size: 0;
            opacity: 0.8;
            width: 48px;
            height: 48px;
            display: block;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
        }

        .carousel-nav-next {
            font-size: 0;
            opacity: 0.8;
            width: 48px;
            height: 48px;
            display: block;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .carousel-nav-prev:disabled, .carousel-nav-next:disabled {
            opacity: .4;
        }
    }
}