/*.menu-dropdown .menu-dropdown-root {
    display: flex;
    align-items: center;
    line-height: normal;
    font-size: 15px;
    font-weight: 300;
    padding: 15px 26px 15px;
    border-radius: 4px;
    height: 48px;
    box-sizing: border-box;

    &:before {
        content: '';
        background: url(../../images/catalog-icon.svg);
        background-repeat: no-repeat;
        display: block;
        width: 18px;
        height: 14px;
        background-position: center;
        margin-right: 10px;
    }
}*/

.menu-dropdown {

    .menu-dropdown-root {
        display: flex;
        align-items: center;
        line-height: normal;
        font-size: 15px;
        font-weight: 300;
        padding: 15px 26px 15px;
        border-radius: 4px;
        height: 48px;
        box-sizing: border-box;

        &:before {
            content: '';
            background: url(../../images/catalog-icon.svg);
            background-repeat: no-repeat;
            display: block;
            width: 18px;
            height: 14px;
            background-position: center;
            margin-right: 10px;
        }
    }

    .menu-dropdown-list {
        border-radius: 4px;
        border: none;

        .menu-dropdown-item {
            &:first-child {
                .menu-dropdown-link-wrap {
                    padding-top: 3px;
                }
            }

            &:last-child {
                .menu-dropdown-link-wrap {
                    padding-bottom: 1px;
                }
            }

            .menu-dropdown-link {
                display: flex;
                align-items: center;
                padding: 8.5px 1.2rem 8.5px 1.0625rem;
                padding-left: 26px;
            }
        }

        .menu-dropdown-item.parent {
            .menu-dropdown-link {
                &:after {
                    content: '';
                    -webkit-mask-image: url(../../images/menu.svg);
                    mask-image: url(../../images/menu.svg);
                    display: block;
                    width: 12px;
                    height: 7px;
                    background-color: currentColor;
                    transform: rotate(270deg);
                }
            }

            .submenu-opened .menu-dropdown-link {
                &:after {
                    transform: rotate(0deg);
                }
            }
        }


        .menu-dropdown-item.submenu-classic.parent {

            .menu-dropdown-sub-block {
                .menu-dropdown-sub-category {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .menu-dropdown-item.submenu-accordion {
            .menu-dropdown-sub-columns-item {
                br {
                    display: none;
                }

                .menu-dropdown-sub-block {
                    padding: 0 0 0 35px;
                }
            }

            .menu-dropdown-sub {
                .menu-dropdown-sub-category {
                    margin-bottom: 10px;
                }
            }
        }

        .menu-dropdown-item.submenu-treeview {
            .menu-dropdown-sub {
                .menu-dropdown-sub-block {
                    /*padding-left: 55px;*/
                }
            }

            .menu-dropdown-sub-category-name {
                display: flex;
            }

            .menu-dropdown-sub-columns-item {
                br {
                    display: none;
                }
            }

            .menu-dropdown-link-wrap {
                .menu-dropdown-treeview-control {
                    padding-left: 20px;
                }

                .menu-dropdown-treeview-control ~ .menu-dropdown-link {
                    padding-left: 20px;
                }

                .menu-dropdown-link {
                    padding: 7.5px 1.2rem 7.5px 1.0625rem;
                    padding-left: 26px;

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

.menu-dropdown.menu-dropdown-classic .menu-dropdown-sub + .menu-dropdown-link-wrap,
.menu-dropdown.menu-dropdown-modern .menu-dropdown-sub + .menu-dropdown-link-wrap,
.menu-dropdown.menu-dropdown-accordion .menu-dropdown-sub + .menu-dropdown-link-wrap {
    padding-right: 0;

    &::after {
        display: none;
    }
}