@use "sass:math";

@import "../../../../styles/_settings.scss", "../../../../styles/functions/_util.scss";

$ghost-btn-margin: auto rem-calc(20px) auto 0 !default;
$ghost-btn-color: #adadad;

$input-height: rem-calc(12px);
$input-width: rem-calc(12px);
$input-margin: 0 rem-calc(8px) 0 0;
$iphone-checkbox-height: rem-calc(18px);
$iphone-checkbox-width: rem-calc(55px);
$iphone-checkbox-dim: rem-calc(2px);
$iphone-checkbox-on-off-width: math.div($iphone-checkbox-width, 2);

.quizzes label .custom-input-native,
.custom-input-native {
    opacity: 0;
    position: absolute;
}

.custom-input-text {
    cursor: pointer;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.custom-input-text__indent {
    padding-left: 20px;
}

.quizzes label .custom-input-radio,
.quizzes label .custom-input-checkbox,
.custom-input-checkbox,
.custom-input-radio {
    cursor: pointer;
    position: relative;
    top: 0;
    display: inline-block;
    width: $input-width;
    height: $input-height;
    margin: $input-margin;
    box-shadow: none;
    border: 1px #C8C7CC solid;
    background: #fff;
    vertical-align: bottom;

    &:hover {
        background: #fff;
    }
}

.quizzes label .custom-input-checkbox,
.custom-input-checkbox {
    border-radius: 4px;
}

.quizzes label .custom-input-checkbox,
.custom-input-checkbox {
    box-shadow: none;
    width: 18px;
    height: 18px;
}

.quizzes label .custom-input-native:checked ~ .custom-input-checkbox,
.custom-input-native:checked ~ .custom-input-checkbox {
    &:after {
        background-image: url(../../images/checkbox.svg);
        width: 20px;
        height: 20px;
        border-radius: 4px;
        box-sizing: border-box;
        background-position: center;
        left: -1px;
        top: -1px;
    }
}

.quizzes label .custom-input-checkbox__abs
.custom-input-checkbox__abs {
    position: absolute;
    left: 0;
    top: 4px;
}

.custom-input-radio {
    border-radius: 50%;
}

.quizzes label .custom-input-checkbox:after,
.custom-input-checkbox:after {
    content: "";
    position: absolute;
    width: $input-width + rem-calc(1px);
    height: $input-height + rem-calc(1px);
    background-repeat: no-repeat;
    left: rem-calc(1px);
    top: rem-calc(-2px);
}

.custom-input-radio:after {
    content: "";
    position: absolute;
    width: math.div($input-width, 2);
    height: math.div($input-height, 2);
    border-radius: 50%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.quizzes label .custom-input-native,
.custom-input-native {

    &:checked ~ .custom-input-checkbox,
    &:checked ~ .custom-input-radio {
        border-width: 1px;
        border-style: solid;
        box-shadow: none;

        &:after {
            border-width: 1px;
            border-style: solid;
            /*border-color: currentColor;*/
        }
    }

    &:disabled ~ .custom-input-text {
        color: #94969b;
    }
}

.custom-input-native {
    &:focus ~ .custom-input-radio,
    &:focus ~ .custom-input-checkbox,
    &:focus ~ .custom-input-radio {
        box-shadow: none;
    }
}

.quizzes label .custom-input-native:checked ~ .custom-input-checkbox:after,
.custom-input-native:checked ~ .custom-input-checkbox:after {
    background-image: url(../../images/checkbox.svg);
}

.quiz-agreement-text {
    padding-left: 0 !important;
    vertical-align: middle;
}

.quizzes label .custom-input-checkbox {
    vertical-align: middle;
}


.custom-iphone-checkbox {
    border-radius: $border-radius-small;
    cursor: pointer;
    display: inline-block;
    height: $iphone-checkbox-height;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    width: $iphone-checkbox-width;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    font-size: $font-size-xsmall;
    transform: translateZ(0);

    & input {
        display: none;
    }

    & input:checked + .custom-iphone-checkbox-slider {
        left: 0;
    }
}

.custom-iphone-checkbox-slider {
    left: -50%;
    position: absolute;
    top: 0;
    bottom: 0;
    -moz-transition: left .25s ease-out;
    -webkit-transition: left .25s ease-out;
    -o-transition: left .25s ease-out;
    transition: left .25s ease-out;
    border-radius: $border-radius-small;
}

.custom-iphone-checkbox-on, .custom-iphone-checkbox-block, .custom-iphone-checkbox-off {
    display: block;
    font-weight: bold;
    height: 100%;
    line-height: $iphone-checkbox-height;
    position: absolute;
    text-align: center;
    top: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.custom-iphone-checkbox-on {
    background: rgb(6, 98, 193);
    color: white;
    left: 0;
    width: $iphone-checkbox-on-off-width + $iphone-checkbox-dim;
    padding-right: $iphone-checkbox-dim;
    border-top-left-radius: $border-radius-small;
    border-bottom-left-radius: $border-radius-small;
}

.custom-iphone-checkbox-off {
    background: #252729;
    color: #8b8b8b;
    padding-left: $iphone-checkbox-dim;
    left: $iphone-checkbox-width - $iphone-checkbox-dim;
    width: $iphone-checkbox-on-off-width;
    border-top-right-radius: $border-radius-small;
    border-bottom-right-radius: $border-radius-small;
}

.custom-iphone-checkbox-block {
    background: #5a5d60;
    border-radius: $border-radius-small;
    height: 100%;
    left: math.div($iphone-checkbox-width, 2);
    width: math.div($iphone-checkbox-width, 2);
}

.btn-ghost {
    border: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: $ghost-btn-margin;
    width: 13px;
    height: 13px;
    color: $ghost-btn-color;

    &:hover {
        text-decoration: none;
        color: $ghost-btn-color;
    }
}

.select-custom {
    select {
        border-color: #EFEFF4;
        background: #F9F9F9;
        color: #8A8A8F;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px #EFEFF4 solid;
        vertical-align: middle;
        width: 100%;
        //height: 48px;
    }

    &::after {
        background: #8A8A8F;
    }
}

.select-custom {
    position: relative;
    display: inline-block;
    width: 100%;
    color: #fff;

    &:after {
        right: rem-calc(10px);
        pointer-events: none;
    }

    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        /* do not forget set background color to select by color-sheme */
        /*border: none;*/
        //border-color: transparent;
        padding: rem-calc(8px) rem-calc(30px) rem-calc(8px) rem-calc(15px);
        border-radius: $border-radius-base;
        width: 100%;
        cursor: pointer;
        border-width: 1px;
        border-style: solid;

        &:hover {
            outline: none;
        }

        &::-ms-expand {
            display: none;
        }

        option {
            background: #fff;
            color: #333;
            border: none;
        }
    }

    &--slim select {
        padding: 0 rem-calc(30px) 0 rem-calc(15px);
        height: rem-calc(26px);
    }

    &--fat select {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}
