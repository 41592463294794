@import "../../../../../styles/_settings.scss", "../../../../../styles/functions/_util.scss";

#zoneDialog {
    .adv-modal-inner {
        width: 760px;
    }

    .adv-modal-close {
        left: inherit;
        right: 24px;
        top: 28px;
        width: 24px;
        height: 24px;

        &:after {
            margin: 0;
        }
    }

    .modal-content {
        padding: 0;
    }

    .zone-dialog {

        &-title {
            font-size: 24px;
            padding: 24px 30px 20px 30px;
            line-height: 130%;
            border-bottom-width: 1px;
            border-bottom-style: solid;
        }


        &-countries {
            padding: 24px 30px 35px 30px;
            display: flex;
            flex-wrap: wrap;
            grid-gap: 5px;
        }

        &-country-link {
            font-size: 14px;
            padding: 12px 18px 12px 14px;
            display: flex;
            align-items: center;
            height: 48px;
            box-sizing: border-box;
            vertical-align: middle;
            border-color: transparent;
            border-width: rem-calc(1px);
            border-style: solid;
            border-radius: $border-radius-base;
        }

        &-country-selected {
            border-color: #ccc;
        }

        &-items {
            display: table;
            width: 100%;
            table-layout: fixed;
        }

        &-item {
            display: table-cell;
            vertical-align: top;
        }


        &-item-link {
            display: block;
            border-radius: $border-radius-base;
            border: 1px solid transparent;
            font-size: 14px;
            border: none;
            font-style: normal;
            font-weight: normal;
            padding: 0;
            margin-bottom: 20px;

            &:hover {
                border-color: #dedddd;
                text-decoration: none;
            }
        }

        &-text {
            font-size: $font-size-xmedium;
            padding-right: $padding-base;
        }

        &-cities {
            padding: 32px 27px 29px 33px;
            border-top: 0;
        }


        &-autocomplete-block {
            padding: 0 30px 0 33px;
            margin-bottom: 0;
        }

        &-cities {
            padding-bottom: $padding-base;
            border-top: 1px solid #e2e3e4;
        }


        &-input-wrap {
            position: relative;
            margin-bottom: 12px;
            margin-top: 12px;

            &:before {
                content: '';
                background: url(../../../images/search.svg);
                background-repeat: no-repeat;
                background-size: 24px;
                display: block;
                width: 46px;
                height: 48px;
                background-position: center;
                position: absolute;
            }

            & > input {
                box-sizing: border-box;
                border-radius: 4px;
                padding-left: 46px;
                font-size: 14px;
                padding-bottom: 12px;
            }
        }

        &-btn {
            margin-bottom: 12px;
            margin-top: 12px;
        }


        &-loading {
            text-align: center;
            padding: 40px 20px;
        }


        &-country-image {
            width: 30px;
            height: 18px;
            border-radius: 2px;
            background-repeat: no-repeat;
            margin-right: 9px;
        }

        &-country-RU {
            background-image: url(../../../images/zone-img/rus.svg);
        }

        &-country-UA {
            background-image: url(../../../images/zone-img/ua.svg);
        }

        &-country-BY {
            background-image: url(../../../images/zone-img/by.svg);
        }

        &-country-KZ {
            background-image: url(../../../images/zone-img/kz.jpg);
        }

        &-autocomplete-title {
            font-size: 16px;
            line-height: 120%;
        }

        &-autocomplete-block {
            margin-bottom: 15px;
        }

        &-text-wrap {
            flex-basis: 100%;
            max-width: 100%;
        }

        &-form-field-wrap {
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    @media screen and (min-width: 48em) {

        .zone-dialog {
            &-search-block {
                flex-basis: 75%;
                max-width: 75%;
            }

            &-btn-block {
                flex-basis: 25%;
                max-width: 25%;
            }
        }
    }
}

