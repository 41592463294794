@import "../../../../styles/functions/_util.scss", "../../../../styles/functions/_prefixer.scss", "../../../../styles/functions/_list.scss", "../../../../styles/_settings.scss";

$input-border-radius: 4px !default;
$input-border: 1px #EFEFF4 solid !default;
$input-placeholder-color: #8A8A8F !default;

$input-big: (padding (rem-calc(16px) rem-calc(20px) rem-calc(15px)), font-size $font-size-medium) !default;
$input-middle: (padding (rem-calc(15px) rem-calc(20px) rem-calc(15px)), font-size $font-size-medium) !default;
$input-small: (padding (rem-calc(8px) rem-calc(20px) rem-calc(8px)), font-size $font-size-medium) !default;
$input-xsmall: (padding (rem-calc(15px) rem-calc(20px) rem-calc(15px)), font-size $font-size-medium) !default;


$textarea-big: (padding (rem-calc(15px) rem-calc(18px) rem-calc(15px)), height rem-calc(100px), font-size $font-size-medium) !default;
$textarea-middle: (padding (rem-calc(15px) rem-calc(18px) rem-calc(15px)), height rem-calc(100px), font-size $font-size-medium) !default;
$textarea-small: (padding (rem-calc(15px) rem-calc(18px) rem-calc(15px)), height rem-calc(100px), font-size $font-size-medium) !default;
$textarea-xsmall: (padding (rem-calc(15px) rem-calc(18px) rem-calc(15px)), height rem-calc(100px), font-size $font-size-medium) !default;

@mixin input-size($input-size-name, $input-size-params) {
    .#{$input-size-name} {
        font-size: list-find-value($input-size-params, font-size);
        padding: list-find-value($input-size-params, padding);
    }
}

@mixin textarea-size($textarea-size-name, $textarea-size-params) {
    .#{$textarea-size-name} {
        padding: list-find-value($textarea-size-params, padding);
        font-size: list-find-value($textarea-size-params, font-size);
        height: list-find-value($textarea-size-params, height);
    }
}

input[type="text"],
input[type="password"],
input[type="search"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="url"] {
    box-sizing: border-box;
    border-radius: $input-border-radius;
    border: $input-border;
    vertical-align: middle;
    width: 100%;
    /*height: 48px;*/
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    /*box-shadow: none;*/ //remove styles for invalid input in FF
    /*line-height: 1.2;*/
    outline: none;
    background-color: #F9F9F9;
    color: #8A8A8F;

    &:hover {
        box-shadow: 0px 0px 3px rgba(41, 41, 169, 0.1);
    }

    &:focus {
        box-shadow: 0px 0px 3px rgba(45, 156, 238, 0.4);
    }

    &[readonly] {
        background-color: #f9f9f9;
    }

    &:disabled {
        background-color: #EFEFF4;
    }
}

textarea {
    box-sizing: border-box;
    border-radius: $input-border-radius;
    border: $input-border;
    vertical-align: middle;
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    /*box-shadow: none;*/ //remove styles for invalid input in FF
    /*line-height: 1.2;*/
    outline: none;
    background-color: #F9F9F9;
    color: #8A8A8F;

    &:hover {
        box-shadow: 0px 0px 3px rgba(41, 41, 169, 0.1);
    }

    &:focus {
        box-shadow: 0px 0px 3px rgba(45, 156, 238, 0.4);
    }

    &[readonly] {
        background-color: #f9f9f9;
    }

    &:disabled {
        background-color: #EFEFF4;
    }
}


.form-field-input {
    .flatpickr-custom {
        &__toggle {
            background-color: #F9F9F9;
            border: $input-border;
        }
    }
}



textarea {
    overflow: auto;
}

input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }
}


@include input-size('input-big', $input-big);
@include input-size('input-middle', $input-middle);
@include input-size('input-small', $input-small);
@include input-size('input-xsmall', $input-xsmall);

@include textarea-size('textarea-big', $textarea-big);
@include textarea-size('textarea-middle', $textarea-middle);
@include textarea-size('textarea-small', $textarea-small);
@include textarea-size('textarea-xsmall', $textarea-xsmall);
