@import '../../../../styles/variables.scss';

.news-page {
    padding-top: .6rem;

    .news-item-title {
        display: flex;

        .main-title {
            flex-grow: 1;
        }

        .news-rss-link {
            display: none;
            font-size: 16px;
        }
    }

    .page-title-row {
        margin-bottom: 20px;
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        .news-item-title {
            .main-title {
                margin-bottom: 0;
                line-height: 130%;
                font-size: 36px;
            }
        }
    }

    .news-category-block {
        margin-bottom: 40px;

        .news-item {
            .news-item-img-col {
                margin-bottom: 20px;
            }

            .h3 {
                padding-right: .625rem;
                padding-left: .625rem;
            }

            .news-list-item-bDescr {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 160%;
                padding-right: .625rem;
                padding-left: .625rem;
            }

            .news-item-href {
                padding-right: .625rem;
                padding-left: .625rem;
                margin-top: 15px;
            }
        }

        .news-item-big {
            margin-bottom: 100px;

            .h3 {
                max-height: 93px;
                overflow: hidden;

                a {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 130%;
                }
            }

            .news-list-item-bDescr {
                max-height: 150px;
                overflow: hidden;
            }
        }

        .news-item-small {
            margin-bottom: 100px;

            .h3 {
                height: 49px;
                overflow: hidden;

                a {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 130%;
                }
            }

            .news-list-item-bDescr {
                height: 65px;
                overflow: hidden;
            }
        }
    }


}

.news-categories {
    margin: 10px 0;

    .news-menu-link.cs-bg-3 {
        background: none;
    }

    .news-menu-link {
        display: inline-block;
        padding: 6px 18px 6px 0;
        border-radius: 6px;
        /*&:first-child {
            padding-right: 15px;
        }*/
    }
}

.news-page-details {
    padding-top: .6rem;


    .news-item-title {
        display: flex;

        .main-title {
            flex-grow: 1;
        }

        .news-rss-link {
            display: none;
            font-size: 16px;
        }
    }


    .page-title-row {
        /*margin-bottom: 40px;*/
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .main-title {
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 130%;
    }

    .news-item-block {
        .news-item-content {
            font-size: 16px;
        }

        .social-btns {
            margin-top: 20px;
        }

        .link-return {
            margin-bottom: 15px;
        }
    }

    .news-page-details-picture {
        img {
            max-height: 400px;
        }
    }
}

.module-blog-category {
    .news-link-title {
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 21px;
        line-height: 130%;
        margin-bottom: 15px;
    }

    .news-categories {
        display: flex;
        flex-wrap: wrap;
    }

    .subheader {
        margin-bottom: 10px;
    }
}

.news-item-title-row {
    padding-bottom: 0;

    .main-title {
        margin-bottom: 0;
    }
}

.news-categories {
    & > .news-menu-link {
        border-radius: 6px;
    }
}

.news-item-categories-wrap {
    flex-shrink: 1;
}

.module-blog-header {
    padding-bottom: 16px;
    padding-top: 16px;

    h1 {
        font-size: 14px;
        margin-bottom: 0;
        text-align: center;
    }
}


@media #{$viewport-md} {

    .news-page-details,
    .news-page {

        .news-item-title {
            .news-rss-link {
                display: block;
            }

            .main-title {
                flex-grow: 0;
            }
        }
    }
}

@media #{$viewport-sm} {
    .news-page {
        .news-category-block {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .module-blog-header {
        padding-bottom: 0;
        padding-top: 0;

        h1 {
            text-align: left;
            font-size: 36px;
            margin-bottom: 0;
        }
    }
}
