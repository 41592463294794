.popular-categories {
    .popular-title {
        font-size: 24px;
        margin-bottom: 24px;
        margin-top: 60px;
    }

    .product-categories {
        display: flex;
        flex-wrap: wrap;
    }

    .product-categories-item-slim {
        position: relative;
        border-width: 1px;
        border-style: solid;
        flex-shrink: 0;
        max-width: none !important;
        margin-right: -1px;
        margin-top: -1px;
        text-decoration: none;
        padding: 40px 20px 20px 20px;
        box-sizing: border-box;

        &:hover {
            z-index: 1;
            text-decoration: none;
        }
    }

    .product-categories-item-inner-slim {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        height: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        border: 0;
    }

    .product-categories-header-slim {
        padding-bottom: 20px;
        padding-top: 40px;
    }

    .product-categories-header-slim-title {
        font-size: 16px;
    }

}
