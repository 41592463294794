@import "../functions/_util.scss";

$invalid-color: #EFEFF4;
$invalid-color-box-shadow: rgba(255, 14, 0, 0.7);
$invalid-bg: #F9F9F9;
$invalid-color-text: #f15959;


[type="text"],
[type="password"],
[type="search"],
[type="email"],
[type="number"],
[type="tel"],
[type="url"],
textarea,
select {
    &.ng-dirty.ng-invalid {
        border-color: #{$invalid-color};
        box-shadow: 0px 0px 3px $invalid-color-box-shadow;
        /*background: #{$invalid-bg}*/;
    }
}

.custom-input-native.ng-dirty.ng-invalid {
    & ~ .custom-input-radio,
    & ~ .custom-input-checkbox {
        border-color: $invalid-color;
        box-shadow: 0px 0px 3px $invalid-color-box-shadow;
    }
}


.ng-submitted.ng-dirty {
    [type="text"],
    [type="password"],
    [type="search"],
    [type="email"],
    [type="number"],
    [type="tel"],
    [type="url"],
    textarea,
    select {
        &.ng-invalid {
            border-color: #{$invalid-color};
            box-shadow: 0px 0px 3px $invalid-color-box-shadow;
            /*background: #{$invalid-bg}*/;
        }
    }

    .custom-input-native.ng-invalid {
        box-shadow: 0px 0px 3px $invalid-color-box-shadow;

        & ~ .custom-input-radio,
        & ~ .custom-input-checkbox {
            border-color: $invalid-color;
            box-shadow: 0px 0px 3px $invalid-color-box-shadow;
        }
    }
}

.validation-popover {
    color: #{$invalid-color-text};
    font-weight: bold;
}

.error-text {
    border-color: #{$invalid-color-text};
}

.error-color {
    color: #{$invalid-color-text};
}
