.product-card {
    .js-reviews-list-root {
        .reviews-list {
            .review-item {
                margin-bottom: 40px;

                .review-item-name {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 150%;
                    display: flex;
                    align-items: center;
                    padding-right: 10px;
                }

                .review-item-date {
                    padding: 0;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 150%;
                    display: flex;
                    align-items: center;
                    padding-right: 10px;
                    border: none;
                }

                .review-item-text {
                    padding-top: 9px;
                }

                .review-item-buttons {
                    margin-top: 25px;
                }

                .review-item:last-child {
                    margin-bottom: 0;
                }
            }

            .reviews-list {
                padding: 1.625rem 0 1.0625rem 1.25rem;
            }
        }
    }

    .review-form-block {
        .review-form-header {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 150%;
            margin-bottom: 16px;
        }

        .review-form {
            .form-field {
                .input-small {
                    height: 50px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    padding-left: 20px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 140%;
                }

                .textarea-small {
                    box-sizing: border-box;
                    border-radius: 4px;
                    padding: 15px 20px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 140%;
                }
            }

            .review-form-button {
                .btn-submit {
                    width: 166px;
                    height: 48px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 140%;
                    margin-right: 15px;
                }

                .btn-action {
                    width: 166px;
                    height: 48px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 140%;
                }
            }
        }
    }
}
