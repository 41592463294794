.breads-item {
    .breads-item-link {
        span {
            font-size: 12px;
        }
    }

    .breads-item-current {
        font-size: 12px;
    }

    &::before {
        content: '';
        background: url(../../images/filter-arrow.svg);
        background-repeat: no-repeat;
        width: 12px;
        height: 7px;
        transform: rotate(90deg);
        background-size: 10px;
    }
}

.breadcrumbs {
    &__item {
        margin-right: 1px;

        &::before {
            align-self: center;
        }
    }
}