.site-head .site-head__menu .menu-general,
.menu-general {

    .menu-general-root-link {
        padding: 0 20px 0 20px;
    }

    .harmonica-tile {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .harmonica-tile-inner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .harmonica-tile-list {
        top: 100%;
        padding: 0;
        min-width: max-content;
        border-radius: 10px;
    }

    .harmonica-tile-row {
        margin: 0;


        &:last-child {
            .harmonica-tile-link {
                border-bottom: 0;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }

        &:first-child {
            .harmonica-tile-link {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
        }

        .harmonica-tile-link {
            display: block;
            box-sizing: border-box;
            padding: 14px 24px;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            font-weight: 300;

            &:hover {
                text-decoration: none;
            }
        }
    }


    .menu-general-category-parent {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 300;
        border-bottom-style: solid;
        border-bottom-width: 1px;


        &:first-child {
            & > a {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
        }

        &:last-child {
            & > a {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }


        &:last-child {
            border-bottom: 0;
        }
    }

    .menu-general-sub-column {
        .menu-general-category-parent {
            &:first-child {
                margin-top: 0;
            }
        }
    }

    .menu-general-icon {
        flex-shrink: 0;
    }
}

.site-head .site-head__menu .menu-general .harmonica-tile {
    padding-top: 0;
    padding-bottom: 0;
}
