
/*.wishlist-page {*/
    .wishlist-remove {
        &::before {
            content: '';
            mask-image: url(../../images/zone-close.svg);
            -webkit-mask-image: url(../../images/zone-close.svg);
            width: 24px;
            height: 24px;
            margin: 10px;
            color: currentColor;
            background-color: currentColor;
        }
    }

    .product-view-action {
        top: 28px;
    }
    .js-wishlist-remove {
        top: -16px;
        right: 0;
    }
/*}*/

