.feedback-page {
    .feedback-theme-changer {
        overflow: hidden;
        justify-content: space-between;

        .feedback-theme-item {
            padding-bottom: 5px;
            margin-bottom: 5px;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 1px solid transparent;
            margin-right: 0;
            font-size: 16px;
        }
    }

    .feedback-input-area {

        .feedback-input {
            flex-basis: 100%;
            margin-right: 0;

            input {
                min-width: 100%;
            }
        }
    }
}


@media (min-width: 760px) {

    .feedback-page {
        .feedback-theme-changer {
            justify-content: flex-start;

            .feedback-theme-item {
                padding-bottom: 32px;
                margin-right: 60px;
                margin-bottom: 0;
                font-size: 18px;
            }
        }

        .feedback-input-area {

            .feedback-input {
                flex-basis: auto;

                &:first-child {
                    margin-right: 13px;
                }

                input {
                    min-width: 300px;
                }
            }
        }
    }
}

.feedback-page {
    /*margin-bottom: 150px;*/

    .feedback-text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
    }

    .feedback-theme-changer {
        position: relative;
        display: flex;
        flex-wrap: wrap;

        &:before {
            content: '';
            position: absolute;
            height: 1px;
            width: 100%;
            padding: 0 9999px;
            margin: 0 -9999px;
            bottom: 0;
        }

        .feedback-theme-item {
            background: none;
            border-radius: 0;
            font-style: normal;
            font-weight: normal;
            line-height: 150%;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .feedback-textarea {
        margin-bottom: 21px;

        .feedback-textarea-title {
            margin-bottom: 10px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
        }

        textarea {
            max-width: 614px;
            border-radius: 4px;
            height: 100px;
        }
    }

    .feedback-input-area {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 21px;

        .feedback-input {

            .feedback-input-title {
                margin-bottom: 10px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 140%;
            }

            input {
                box-sizing: border-box;
                border-radius: 4px;
                height: 48px;
            }
        }
    }

    .btn-submit {
        border-radius: 4px;
        max-width: 152px;
        height: 48px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
    }
}
