.carousel-main {
    margin-bottom: 12px;
}

.carousel-main.carousel-initilized {
    img {
        height: auto;
    }
}

.block.voting {

    .voting-answers {
        .voting-answers-row {
            label {
                display: flex;
                align-items: center;

                .custom-input-radio {
                    width: 24px;
                    height: 24px;
                    box-shadow: none;
                    top: 0;
                }

                .custom-input-text {
                    font-size: 14px;
                }
            }
        }

        .custom-input-native:checked ~ .custom-input-radio {
            width: 24px;
            height: 24px;
            box-shadow: none;

            &:after {
                width: 10px;
                height: 10px;
            }
        }
    }
}


.slider-main-block {
    .carousel-horizontal .carousel-nav-inside .carousel-nav-prev {
        left: 12px;
    }

    .carousel-horizontal .carousel-nav-inside .carousel-nav-next {
        right: 12px;
    }

    .carousel-dots {
        position: absolute;
        right: 43px;
        bottom: 40px;
    }
}


.news-block {

    .block-content {
        .news-block-anno-link {
            font-size: 14px;
        }

        .news-block-date-text {
            font-size: 13px;
        }
    }
}


.carousel-inner--min-height-default,
.carousel-inner--min-height-twocolumns {
    min-height: 200px;
}