.products-view-tile .carousel-colors.carousel-colors-icon .carousel-inner,
.products-view-list .carousel-colors.carousel-colors-icon .carousel-inner,
.products-view-table .carousel-colors.carousel-colors-icon .carousel-inner {
    margin: 0;

    .color-viewer-list {
        padding: 6px 0;

        .color-viewer-item-wrap {
            width: auto !important;
            max-width: initial !important;
            flex-basis: auto !important;
            padding: 0;

            .color-viewer-item-block {
                padding: 0;
                margin-right: 5px;
                display: block;
                width: auto;
                border-radius: 100%;
                padding: 2px;
                /*max-height: 26px;*/

                .color-viewer-inner {
                    border-radius: 100%;
                    margin: 0;
                    padding: 0px;
                }
            }

            .color-viewer-item-block.color-viewer-item-selected {
                border-radius: 100%;
                /*height: 26px;*/
                padding: 2px;
            }
        }
    }
}

.color-viewer-inner {
    margin: 0;
}

.color-viewer-list.color-viewer-list--iconandtext {
    .color-viewer-inner {
        border-radius: 50%;
    }
}

