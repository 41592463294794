.sidebar.sidebar--cart {
    left: auto;
    right: 0;
    width: 350px;
    transform: translate3d(100%, 0, 0);

    .sidebar__content {
        margin-right: 46px;
        margin-top: 5px;
    }

    .sidebar__content.sidebar__content--without-footer {
        /*height: calc(100vh - 86px - 274px - 5px);*/
        height: calc(100vh - 88px - var(--cartFooterHeight));
    }

    .site-head-cart {
        padding: 40px 36px 40px 40px;
    }

    .sidebar__header {
        margin: 36px 47px 0 40px;
        padding: 0 30px 15px 0;
    }

    .sidebar__close {
        right: -7px;
        top: 0;
    }
}

.sidebars-container.sidebars-container--activated {
    .sidebar.sidebar--cart {
        transform: translate3d(0, 0, 0);
    }
}
