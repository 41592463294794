.vanilla-dropdown {
    position: relative;

    &__list-item {
        display: block;
        padding: 20px;
    }

    vanilla-dropdown-selected {
        display: block;
    }
}

.vanilla-dropdown.is-open {
    .vanilla-dropdown__list {
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 1001;



        background-color: #fff;
    }
}