.myaccount-page {

    &__tabs {
        margin-bottom: 50px;
    }

    .order-history-details-result-list {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .myaccount__wishlist {
        .wishlist-remove {
            top: -16px;
        }
    }

    .order-history-header-item {
        font-weight: normal;
        width: 100%;
        padding-bottom: 24px;
        background-color: transparent;

        &__paied {
            text-align: center;
        }

        &::after {
            display: none;
        }
    }

    .order-history-body-item {
        &__paied {
            text-align: center;
        }

        &__sum {
            text-align: right;
            padding-right: 0;
        }

        &__paied-text {
            font-size: 0;
            width: 18px;
            height: 17px;
            display: inline-block;
            position: absolute;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .order-history-body-item.order-history-body-item-cost::after {
        display: none;
    }

    .order-history-body-item__paied--positive {
        .order-history-body-item__paied-text {
            background-image: url(../../images/lk-payment.svg);
        }
    }

    .order-history-body-item__paied--negative {
        .order-history-body-item__paied-text {
            background-image: url(../../images/lk-no-payment.svg);
        }
    }

    .order-history-header-item, .order-history-body-item {
        &:first-child {
            padding-left: 32px;
            padding-top: 15px;
            padding-bottom: 24px;
            width: 237px;
        }

        &:last-child {
            padding-right: 27px;
        }
    }

    .order-history-header-item__cost {
        text-align: right;
    }

    .order-history-body-item__order-date-time {
        white-space: nowrap;
    }

    .order-history-body-item__order-number {
        display: block;
        margin-right: 10px;
    }

    .order-history-footer-item {
        padding-top: 32px;
        padding-right: 27px;

        .order-history-footer-result,
        .order-history-footer-text {
            font-size: 18px;
            font-weight: 400;
        }

        .order-history-footer-note {
            font-size: 12px;
        }
    }

    .order-history-details {
        &__return-order-list-link {
            padding-left: 20px;
            display: flex;
            align-items: center;
            position: relative;
            font-size: 14px;
            margin-bottom: 15px;

            &::before {
                content: "";
                -webkit-mask-image: url(../../images/arrow-spinbox.svg);
                mask-image: url(../../images/arrow-spinbox.svg);
                width: 12px;
                height: 9px;
                display: block;
                position: absolute;
                transform: rotate( 90deg );
                background-repeat: no-repeat;
                left: 0;
            }
        }
    }

    .order-history-details-products-header {
        display: none;
    }

    .order-history-details-header {
        &__orderdate,
        &__is-paied,
        &__status-name {
            font-size: 14px;
        }
    }
}


