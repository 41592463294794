.payment-list {
    .payment-item-radio {
        padding-top: 0;
        padding-bottom: 0;
    }

    .payment-item-info {
        line-height: 1;

        .form-field-name {
            margin-bottom: 0;
        }
    }
}
