.additional-text {
    margin-bottom: 115px;
    margin-top: 70px;

    &__title {
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 25px;
    }

    &__main {
        font-size: 14px;
        line-height: 160%;
    }

    &__href {
        font-size: 14px;
        margin-top: 20px;
    }
}


.additional-text.additional-text--collapse {
    .additional-text__main {
        position: relative;
        height: 135px;
        overflow: hidden;

        &:before {
            content: "";
            display: block;
            height: 91px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        }
    }


    .additional-text__main.show {
        height: auto;
        overflow: visible;

        &:before {
            display: none;
        }
    }
}