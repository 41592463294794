.pagenumberer {
    text-align: center;

    .pagenumberer-item {
        min-width: 50px;
        height: 50px;
        box-sizing: border-box;
        text-align: center;
        padding-top: 17px;
        border-radius: 4px;
        margin-right: 4px;
    }

    .pagenumberer-next {
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        text-align: center;
        padding-top: 14px;
        border-radius: 4px;
        display: inline-block;

        .icon-right-open-after {
            &:after {
                content: '';
                -webkit-mask-image: url(../../images/paging-active.svg);
                mask-image: url(../../images/paging-active.svg);
                width: 19px;
                height: 14px;
                background-repeat: no-repeat;
            }
        }
    }

    .pagenumberer-ellipsis {
        padding-right: 7px;
        padding-left: 2px;
    }

    .pagenumberer-prev {
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        text-align: center;
        padding-top: 14px;
        border-radius: 4px;
        display: inline-block;
        margin-right: 4px;

        .icon-left-open-after {
            &:after {
                content: '';
                -webkit-mask-image: url(../../images/paging-active.svg);
                mask-image: url(../../images/paging-active.svg);
                width: 19px;
                height: 14px;
                background-repeat: no-repeat;
                transform: rotate(180deg);
            }
        }
    }
}
