.personal-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 360px;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

    &__avatar {
        padding-top: 6px;
    }

    &__exit-icon,
    &__list-item-icon {
        display: flex;
        align-items: center;
    }

    &__info {
        display: grid;
        grid-template-columns: 24px auto;
        column-gap: 22px;
        padding: 27px 27px 24px 38px;
    }

    &__list-link {
        list-style-type: none;
        padding: 0 37px 0 37px;
        margin: 0;
        border-top-width: 1px;
        border-top-style: solid;
    }

    &__list-item {
        position: relative;
        display: grid;
        grid-template-columns: 24px auto;
        align-items: center;
        column-gap: 22px;
        padding-top: 14px;
        padding-bottom: 14px;
        border-bottom-width: 1px;
        border-bottom-style: solid;

        &:last-child {
            border-bottom: 0;
        }
    }

    &__list-item-count {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &__exit-link {
        font-size: 14px;
    }

    &__list-item-link {
        padding-right: 20px;
        padding-top: 3px;
        font-size: 14px;
    }

    &__exit {
        display: grid;
        grid-template-columns: 24px auto;
        align-items: center;
        column-gap: 22px;
        padding: 16px 37px 17px 37px;
        border-top-width: 1px;
        border-top-style: solid;
    }
}
