.login-row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.login-block-auth {
    padding-top: 0;

    .login-title {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 130%;
        margin-bottom: 17px;
    }

    .login-flex {
        display: flex;
        /*margin-bottom: 24px;*/
        flex-wrap: wrap;

        .login-field {
            margin-right: 0;
            flex-basis: 70%;

            .login-field-title {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 140%;
                margin-bottom: 7px;
            }
        }
    }

    .login-btn-block {
        display: flex;
        align-items: center;

        .login-forget-block {
            margin-left: 20px;

            .link-forget {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 160%;
            }
        }
    }
}


.login-block-registration {
    padding: 0;
    background: none;

    .login-title {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 130%;
    }

    .login-block-registration-text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        margin-bottom: 24px;
    }
}
