@import '../../../../styles/variables.scss';

.advantages {
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    font-size: 14px;
    margin-bottom: 25px;

    .advantages-item {
        margin-right: 30px;
        flex: 25% 1 1;

        &:last-child {
            border-right: none;
            margin-right: 0;
        }

        .advantages-item-img {
            max-height: 40px;
            height: 40px;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
        }
    }
}


@media #{$viewport-lg} {

    .advantages {
        padding: 40px 70px 40px 70px;

        .advantages-item {
            margin-right: 50px;
        }
    }
}

