.catalog-filter {

    .catalog-filter-block {
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    .adv-popover-content {
        .catalog-filter-popover-button {
            .btn-action {
                display: block;
                width: 100%;
            }
        }
    }

    .catalog-filter-content {
        border: none;

        .catalog-filter-block {

            .catalog-filter-block-header {
                padding: 13.5px 19px 13.5px 24px;
                border: none;
                font-size: 14px;

                &:after {
                    content: '';
                    -webkit-mask-image: url(../../../../images/menu.svg);
                    mask-image: url(../../../../images/menu.svg);
                    display: block;
                    width: 12px;
                    height: 7px;
                    right: 19px;
                    top: 22px;
                    transform: rotate(270deg);
                    background-color: currentColor;
                }

                .catalog-filter-block-title {
                    font-size: 14px;
                }
            }

            .catalog-filter-block-header.catalog-filter-block-header-open {
                &:after {
                    transform: rotate(180deg);
                }
            }

            .catalog-filter-block-content {
                border: none;
                padding: 8px 19px 16px 19px;

                .color-viewer-inner,
                .color-viewer-item-block {
                    border-radius: 50%;
                }
            }

            .catalog-filter-block-content-select {
                .select-custom {
                    select {
                        box-sizing: border-box;
                        border-radius: 4px;
                        font-size: 14px;
                    }

                    &:after {
                        content: '';
                        background: url(../../../../images/select-arrow.svg);
                        background-repeat: no-repeat;
                        width: 12px;
                        height: 7px;
                    }
                }
            }

            .catalog-filter-block-content-checkbox {
                margin-left: 0;

                [data-ng-switch="::item.Control"] {
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .catalog-filter-row {
                    label {
                        display: flex;
                        align-items: center;

                        .custom-input-text {
                            font-size: 14px;
                            position: relative;
                            top: 3px;
                        }
                    }
                }
            }

            .catalog-filter-block-content-range {
                .ngrs-runner {
                    .ngrs-handle {
                        height: 24px;
                        width: 24px;

                        i {
                            width: 8px;
                            height: 8px;
                            display: block;
                            border-radius: 24px;
                            position: absolute;
                            right: 0;
                            left: 50%;
                            top: 50%;
                            bottom: 0;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }

            .ngrs-range-slider {
                input {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }

        .catalog-filter-footer {
            text-align: center;
            padding-top: 20px;
            padding-bottom: 27px;
            border: none;

            & > .btn {
                margin: 5px;
            }
        }
    }
}
