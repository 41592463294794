.modal {
    .zoomer-inner + .zoomer-window {
        cursor: crosshair;
        pointer-events: auto;
    }
}

.zoomer-inner + .zoomer-window {
    cursor: auto;
    pointer-events: none;
}
