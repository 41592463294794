.subscribe-block {
    padding: 55px 9999px 52px 9999px;
    margin: 0 -9999px 2.5rem -9999px;
    /*padding: 55px 0 52px 0;
    margin: 0 0 2.5rem 0;*/
    margin-bottom: 0;
    border-radius: 0;



    .subscribe-block-elements-wrap {
        margin-left: 0;
        margin-right: 0;
        justify-content: center;

        .subscribe-block-button,
        .subscribe-block-field,
        .subscribe-block-text {
            padding-left: 0;
            padding-right: 0;
            flex-basis: auto;
            max-width: none;
        }

        .subscribe-block-text {
            margin-right: 25px;
            font-size: 18px;
        }

        .subscribe-block-field {


            input {
                border: none;
                padding: 11px;
                font-size: 14px;
                height: 40px;
                width: 400px;
                margin-right: 10px;
            }
        }
    }



    .subscribe-block-button {
        input {
            border-radius: 4px;
            font-weight: normal;
            width: 128px;
            font-size: 14px;
            padding: 11px;
            border: 0;
        }
    }
}
