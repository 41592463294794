.products-view-variants {
    display: grid;
    grid-template-columns: 24px 24px 24px;
    grid-gap: 14px;
    justify-content: flex-end;

    .products-view-variants-tile {
        box-sizing: border-box;
        /*width: 24px;*/
        height: 24px;
        padding: 0;
        border: none;

        &:before {
            content: '';
            -webkit-mask-image: url(../../../../images/product-tile-selected.svg);
            mask-image: url(../../../../images/product-tile-selected.svg);
            width: 24px;
            height: 24px;
            margin-right: 0;
        }
    }

    .products-view-variants-list {
        box-sizing: border-box;
        /*width: 24px;*/
        height: 24px;
        padding: 0;
        border: none;

        &:before {
            content: '';
            mask-image: url(../../../../images/product-list-selected.svg);
            -webkit-mask-image: url(../../../../images/product-list-selected.svg);
            background-repeat: no-repeat;
            width: 19px;
            height: 24px;
            margin-right: 0;
            background-position: center;
        }
    }

    .products-view-variants-table {
        box-sizing: border-box;
        /*width: 24px;*/
        height: 24px;
        padding: 0;
        border: none;

        &:before {
            content: '';
            -webkit-mask-image: url(../../../../images/product-table-selected.svg);
            mask-image: url(../../../../images/product-table-selected.svg);
            width: 22px;
            height: 24px;
            margin-right: 0;
        }
    }
}


.products-view-tile {

    .products-view-item {
        border: none;
    }

    .products-view-info {
        .products-view-name-default {
            height: 3rem;
            text-align: left;
            margin: 0;
            margin-top: 1.5625rem;

            .products-view-name-link {
                font-size: 16px;
            }
        }

        .products-view-name-slim {
            text-align: left;
            margin: 0;
            margin-top: 1.5625rem;

            .products-view-name-link {
                font-size: 16px;
            }
        }
    }

    .products-view-pictures {
        z-index: 0;
    }

    .products-view-rating {
        text-align: left;
        margin-top: 0;

        .rating {
            .rating-item {
                &:before {
                    width: 12px;
                    height: 12px;
                    display: block;
                    font-size: 73%;
                    margin-right: 0px;
                }
            }
        }
    }

    .products-view-price-block {
        padding: 0;

        .products-view-price {
            height: auto;
            &::before {
                display: none;
            }
        }

        .price-current, .price-new {
            font-size: 24px;
        }
    }


    .product-view-available {
        font-size: 12px;
        margin-bottom: 8px;
        margin-top: 8px;
    }

    .product-view-noAvailable {
        font-size: 12px;
        margin-bottom: 8px;
        margin-top: 8px;
    }

    .product-view-button {
        margin-top: 10px;

        .product-view-button-wrap {
            .button_block {
                .products-view-buttons {
                    padding-bottom: 0;
                }
            }
        }
    }
}

.products-view-item:hover {
    box-shadow: none;

    .product-view-button {
        opacity: 1;
        visibility: visible;
        height: auto;
        display: block;
    }
}

.products-view-tile .products-view-pictures {
    padding:0;
}

.products-view-list {
    .products-view-block {
        padding: 15px 0;

        .products-view-info {
            .products-view-rating {
                margin-bottom: 5px;
            }
        }

        .product-view-noAvailable, .product-view-available {
            width: 100%;
            padding-left: 0.75rem;
        }

        .product-view-action {
            right: 5px;
            left: initial;
        }
    }

    .product-view-available {
        font-size: 12px;
        margin-bottom: 8px;
        margin-top: 8px;
    }

    .product-view-noAvailable {
        font-size: 12px;
        margin-bottom: 8px;
        margin-top: 8px;
    }

    .products-view-compare {
        right: 0;
        left: inherit;
    }

    .products-view-price-block {
        .price-current, .price-new {
            font-size: 24px;
        }

        .products-view-price {
            .price-current {
                margin-top: 0;
            }
        }

        .products-view-buttons-cell {
            vertical-align: middle;

            .products-view-buttons {
                margin-top: 0;
            }
        }
    }

    .product-view-action {
        .products-view-compare {
            .compare-control {
                .custom-input-text-custom {
                    display: none;
                }
            }
        }

        .products-view-wishlist {
            .wishlist-control {
                .custom-input-text-custom {
                    display: none;
                }
            }
        }
    }
}

.products-view-table {
    .products-view-block {
        padding: 10px 0;

        .products-view-item {
            align-items: end;
        }

        .products-view-info {
            .products-view-rating {
                margin-bottom: 5px;
            }
        }

        .products-view-labels {
            margin-right: 10px;
        }

        .products-view-pictures {
            z-index: 0;
            
            > div{
                min-height: 0 !important;
            }
        }
    }

    .photo-view-list__item-wrap {
        min-height: auto !important;
    }

    .product-view-available, .product-view-noAvailable {
        display: none;
    }

    .product-view-action {
        .products-view-compare {
            .compare-control {
                .custom-input-text-custom {
                    display: none;
                }
            }
        }

        .products-view-wishlist {
            .wishlist-control {
                .custom-input-text-custom {
                    display: none;
                }
            }
        }
    }

    .product-view-button {
        .products-view-buttons-cell {
            padding-right: 0;

            .products-view-buttons {
                margin-top: 0;
            }
        }
    }

    .product-view-available-tile, .product-view-noAvailable-tile {
        display: block;
        margin-top: 6px;
        margin-right: 10px;
        margin-left: 5px;
    }

    .product-view-available {
        font-size: 12px;
        margin-bottom: 0;
        margin-top: 3px;
        margin-right: 10px;
        text-align: right;
    }

    .product-view-noAvailable {
        font-size: 12px;
        margin-bottom: 0;
        margin-top: 3px;
        margin-right: 10px;
    }

    .products-view-compare {
        position: initial;
    }

    .product-view-action {
        top: 0px;
        left: 0px;
    }

    .products-view-price-block {
        .price-current, .price-new {
            font-size: 24px;
        }

        .products-view-price {
            padding-top: 0;

            .price-current {
                margin-top: 0;
            }
        }

        .products-view-buttons-cell {
            vertical-align: middle;

            .products-view-buttons {
                margin-top: 0;
            }
        }
    }

    .photo-view-list__nav-wrap {
        display: block;
    }
}

.product-view-available-tile, .product-view-noAvailable-tile {
    display: none;
}

.products-view-item {
    .product-view-action {
        .products-view-compare {
            .custom-input-text {
                display: none;
            }
        }

        .products-view-wishlist {
            .custom-input-text {
                display: none;
            }
        }
    }
}

.products-view-label-inner {
    border-radius: 0;
    font-weight: 400;
}

.product-categories-item-thin {
    .products-view-tile {
        margin-bottom: 40px;
    }

    .products-view-tile .products-view-block {
        padding: 30px;
    }

    .product-categories-thin-total {
        .product-categories-thin-total-count,
        .product-categories-thin-total-text {
            font-weight: normal;
        }
    }
}

.products-view-tile .products-view-meta {
    text-align: left;
}

.products-view {
    .color-viewer-list {
        padding-top: 6px;
        padding-bottom: 6px;
    }
}


.photo-view-list__item {
    .products-view-picture-link {
        font-size: 0;

        img {
            font-size: 12px;
        }
    }
}

.products-view-sort-result,
.products-view-sort {
    display: flex;
    align-items: center;
}

.products-view-sort-text {
    padding-top: 11px;
    padding-bottom: 11px;
}

.products-view-tile .price-text-instead {
    text-align: left;
}