.myaccount-page {
    .cart-full-cost {
        padding-right: 24px;
    }
}


.order-history-details-header {
    .order-history-details-header-selected {
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 130%;
    }

    & > span {
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 130%;
    }
}

.order-history-details-products {
    margin-top: 40px;

    .cart-full-header {
        .cart-full-header-item {
            padding-top: 0;
        }

        .cart-full-price,
        .cart-full-cost,
        .cart-full-amount {
            text-align: right;
        }
    }


    .cart-full-product {
        padding-right: 20px;
    }

    .cart-full-name-link {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;
        display: flex;
        align-items: center;
    }


    .cart-full-amount {
        text-align: right;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;
    }


    .order-history-details-result {
        padding: 24px;

        .order-history-details-result-list {
            width: 100%;
        }

        .order-history-details-result-name {
            text-align: left;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 150%;
        }

        .order-history-details-result-name-total,
        .order-history-details-result-selected {
            font-size: 24px;
            font-weight: normal;
        }
    }
}

.orderComment {
    margin-top: 55px;
}

.button-submit {
    margin-top: 50px;
    margin-bottom: 60px;
}

.order-history-details-result-row {
    margin-bottom: 5px;
}

.order-history-details-result-value {
    font-size: 18px;
}


.order-history-details-info {
    .order-history-details-info-title {
        font-weight: normal;
        font-size: 18px;
        margin-bottom: 25px;
    }

    .order-history-details-info-name,
    .order-history-details-info-value {
        padding: 7px 0;
    }

    .order-history-details-info-name {
        padding-right: 20px;
    }
}

.order-history-details-print {
    font-size: 14px;
}