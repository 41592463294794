.social-block {

    &__list {
        display: grid;
        grid-gap: 17px;
        list-style-type: none;
        margin: 0;
        padding: 0;
        grid-auto-columns: 24px;
        grid-auto-flow: column;
    }


    &__link.social-block__link--vk:hover {
        color: #2787f5;
    }

    &__link.social-block__link--fb:hover {
        color: #2d88ff;
    }

    &__link.social-block__link--yt:hover {
        color: #ff0000;
    }

    &__link.social-block__link--tw:hover {
        color: #17a8df;
    }

    &__link.social-block__link--ok:hover {
        color: #ef8e1d;
    }

    &__link.social-block__link--telegram:hover {
        color: #2d9ced;
    }
    /*&__link.social-block__link--inst:not(:hover) .social-block__inst-icon-inner {
        fill: currentColor;
    }

    &__link.social-block__link--fb:not(:hover) .social-block__fb-icon-inner {
        fill: currentColor;
    }*/
}
