.spinbox-block {
    position: relative;
}

.spinbox__arrows {
    right: 26px;
    position: absolute;

    .spinbox-more:before {
        content: '';
        mask-image: url(../../../../images/arrow-spinbox.svg);
        -webkit-mask-image: url(../../../../images/arrow-spinbox.svg);
        background-repeat: no-repeat;
        width: 12px;
        height: 9px;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 0;
        transform: rotate(180deg);
        vertical-align: top;
    }

    .spinbox-less:before {
        content: '';
        -webkit-mask-image: url(../../../../images/arrow-spinbox.svg);
        mask-image: url(../../../../images/arrow-spinbox.svg);
        background-repeat: no-repeat;
        width: 12px;
        height: 9px;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 0;
        vertical-align: bottom;
    }
}

.spinbox-button-disabled {
    opacity: 0.5;
}

.logo-generator-modal,
.cart-popup-spinbox {
    .spinbox__arrows {
        right: 1px;
    }
}