.adv-modal, .adv-modal-floating {
    .modal-header {
        border-bottom-color: #EFEFF4;
        padding: 24px 30px 20px 30px;
    }

    .modal-content {
        padding: 24px 30px 35px 30px;
    }

    .modal-footer {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 0;
        padding-bottom: 30px;
        border-top: 0;
        background-color: #fff;
    }
}

.buy-one-click-dialog {
    .buy-one-click-buttons {
        margin-top: 35px;
    }
}

.form-field-control {
    .form-field-name-wrap,
    .form-field-input-wrap {
        flex-basis: 100%;
        max-width: 100%;
    }
}
