.autocompleter-cross {
    top: 11px;
    right: 20px;
}

.autocompleter-list {
    max-height: 520px;

    .autocompleter-list-item.products {
        padding-bottom: 0;

        .autocompleter-list-item-list-item {
            padding-bottom: 18px;
            padding-top: 18px;
            border-bottom: 1px solid #EFEFF4;
        }

        .autocompleter-list-item-list-item:last-child {
            border-bottom: 0;
        }
    }

    .autocompleter-list-item {
        padding: 20px 33px 18px 20px;
        border-top: none;


        &:last-child {
            border-bottom: none;
        }

        .search-item-title {
            font-weight: normal;
            padding: 0;
            padding-bottom: 18px;
            font-size: 18px;
            margin-bottom: 0;
        }

        .autocompleter-list-item-list {

            .autocompleter-category-link {
                padding: 0;
                font-size: 16px;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }

                .col-xs {
                    padding: 0;
                }
            }

            .autocompleter-product-price {
                .autocompleter-product-price-text {
                    display: none;
                }

                .price-new, .price-current {
                    font-size: 16px;
                }

                .price-old {
                    margin-bottom: 2px;
                }
            }
        }
    }

    .autocompleter-list-item.categories {
        .autocompleter-list-item-list {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
            .autocompleter-list-item-list-item {
                .autocompleter-list-item-list-item-content {
                    padding-bottom: 15px;
                    padding-top: 15px;
                    padding-left: 10px;
                    padding-right: 10px;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    box-sizing: border-box;
                }

                &:last-child .autocompleter-list-item-list-item-content {
                    border-bottom: 0;
                }
            }
        }
    }

    .autocompleter-list-item.products {
        .autocompleter-list-item-list {
            .autocompleter-list-item-product {
                padding: 0;
                text-decoration: none;

                .autocompleter-product-data {
                    .autocompleter-product-name {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 150%;
                    }
                }
            }
        }
    }
}

.autocompleter-list-item-footer {
    .autocompleter-list-link-all {
        font-size: 14px;
    }
}
