.site-head-site-head-search {
    position: relative;

    .site-head-search-input {
        padding-left: 47px;
        border: 0;
        /*border: none;
        color: black;*/
        margin-bottom: 0;
        /*border-radius: 4px;
        height: 48px;*/
    }

    .site-head-search-btn {
        width: 46px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 50%;
        /*transform: translateY(-22px);*/
        transform: translateY(-24px);
        position: absolute;
        border: 0;
        cursor: pointer;

        &:before {
            content: '';
            mask-image: url(../../images/search.svg);
            -webkit-mask-image: url(../../images/search.svg);
            background-repeat: no-repeat;
            background-size: 24px;
            display: block;
            width: 24px;
            height: 24px;
            background-position: center;
        }
    }
}

.site-head-search-example {
    margin-top: 5px;

    .site-head-search-example-text {
        color: currentColor;
    }
}

.site-head-cart {
    margin-bottom: 0;
}

.site-head-phone-col {
    display: flex;
    /*flex-wrap: wrap;*/
    align-items: center;
    justify-content: flex-end;
    /*flex-grow: 1;
    flex-shrink: 0;*/
}

.site-head-logo-block {
    text-align: center;
}

.site-head-logo, .site-head-menu-col, .site-head-phone-col {
    margin-top: 26px;
    margin-bottom: 14px;
}

/*address-block*/

.address-block {
    display: flex;
    align-items: center;

    &__icon {
        padding-right: 5px;
        display: flex;
    }

    &__text {
        font-style: normal;
        font-size: 14px;
        line-height: 1.4;
    }
}

/*working-hours*/

.working-hours {
    display: flex;
    align-items: center;

    &__icon {
        display: flex;
        padding-right: 5px;
    }

    &__text {
        font-style: normal;
        font-size: 14px;
        line-height: 1.4;
    }
}


.phones-number {
    &__order-call {
        p {
            margin-bottom: 0;
        }
    }

    .email {

        &::before {
            display: none;
        }
    }
}



@media(max-width: 1279px) {


    .cart-mini-main {
        &__text {
            display: none;
        }
    }

    .site-head {
        &__info {
            .phones-number {
                &__dropdown {
                    padding-left: 0;
                    padding-right: 0;

                    .phones-number__list {
                        right: auto;
                    }
                }

                &__text {
                    font-size: 14px;
                }
            }

            .address-block {
                &__text {
                    font-size: 12px;
                }
            }

            .working-hours {
                &__text {
                    font-size: 12px;
                }
            }
        }
    }
}
