@import '../../../../scripts/_common/carousel/styles/carousel.scss'; 
// для модуля рекомендованные товары в корзине 

.cart-mini-scrollbar {
    max-height: initial;
}

.cart-title {
    h1 {
        font-size: 36px;
    }
}

.cart-full-header-item {
    font-weight: normal;
    font-size: 18px;
}

.cart-full-name-link {
    font-size: 18px;
}

.cart-full-body .cart-full-price, .cart-full-body .cart-full-cost {
    font-size: 18px;
}

.cart-full-error {
    text-align: right;
}

.cart-full-amount {
    .cart-full-amount-control {
        width: 128px;
    }

    .spinbox-block {
        position: relative;

        .spinbox-input-wrap {
            input {
                box-sizing: border-box;
                border-radius: 4px;
                width: 107px;
                padding: 12.5px 16px;
                color: black;
                font-size: 14px;
            }
        }
    }
}

.cart-full-result-block-wrap {
    background-color: transparent;
}

.cart-full-footer {
    /*.cart-full-footer-sum {*/
    .cart-full-footer-sum-left {
        border: none;
        font-size: 18px;
        padding-left: 23px;
        padding: 24px;
        border: none;
    }

    .cart-full-addition {
        border: none;
    }

    .cart-full-result-block {
        border: none;

        .cart-full-summary-price,
        .cart-full-summary-name {
            font-size: 18px;
        }
    }

    .cart-full-footer-total-left {
        font-size: 18px;
        padding-left: 23px;
        padding: 24px;
        border: none;
    }

    .cart-full-result-block {
        border: none;


        .cart-full-result-name {
            font-size: 24px;
        }

        .cart-full-result-price {
            font-size: 24px;
            font-weight: normal;
        }
    }


    .cart-full-addition {
        border: none;
    }

    .cart-full-bonus {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        align-self: flex-end;
    }
}

.cart-full-buttons {
    .btn-action {
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: normal;
        padding: 13px 24px;
        font-size: 16px;
    }

    .btn-submit {
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: normal;
        padding: 13px 24px;
        font-size: 16px;
        margin-left: 10px;
    }

    #ONclientWrap {
        padding-left: 0;
    }
}

.cart-full-remove {
    a {
        height: 19px;
        width: 16px;
        display: block;
        margin-left: 10px;

        &::before {
            content: '';
            width: 14px;
            height: 18px;
            -webkit-mask-image: url(../../../../images/delete.svg);
            mask-image: url(../../../../images/delete.svg);
        }
    }
}

#modalBuyOneClickInCart {
    .modal-content {
        padding: 20px;

        .form-field-name {
            font-size: 14px;
        }

        .form-field-input {
            input {
                font-size: 14px;
            }
        }
    }

    .modal-header {
        padding: 15px 20px;
    }

    .buy-one-click-text {
        font-size: 14px;
    }
}

.sidebar {
    .cart-mini-empty,
    div[data-cart-mini-list],
    .cart-mini,
    .site-head-cart {
        height: 100%;
        box-sizing: border-box;
    }

    .cart-mini-empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .cart-mini-empty-text {
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        min-height: 40px;
    }
}

.site-head-cart {
    .spinbox-block {
        .spinbox__arrows {
            position: static;
        }
    }
}


.cart-mini-main {
    font-size: 15px !important;
}

.cart-mini-footer-custom {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 40px 40px 60px 40px;
    left: 0;
}
.cart-mini-module:not(:empty) {
    margin-bottom: 10px;
}
.cart-mini-result-block-custom {

}

.cart-mini-buttons-cart {
    display: block;
    border-radius: 0;
    text-align: center;
    padding: 8px 10px;
    font-size: 18px;
    margin-bottom: 18px;
    font-weight: 300;
    border-radius: 4px;

    &:hover {
        text-decoration: none;
        -webkit-transition: .3s;
        transition: .3s;
    }
}

.cart-mini-buttons-checkout {
    display: block;
    border-radius: 0;
    text-align: center;
    padding: 10px 10px;
    font-size: 18px;
    border-radius: 4px;
    font-weight: 300;
}

.cart-mini-result-value {
    font-weight: 300;
}

.cart-mini-buttons-checkout:hover {
    text-decoration: none;
    -webkit-transition: .3s;
    transition: .3s;
}

.cart-mini-result-row-totals {
    padding: 10px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.cart-mini-result-row-totals .cart-mini-result-name {
    font-size: 20px;
}

.cart-mini-result-row-totals .cart-mini-result-value {
    font-size: 20px;
}

.cart-mini-list .cart-mini-info .cart-mini-name {
    font-size: 16px;
    font-weight: 500;
}


.cart-mini-result-block-custom-mini-info {
    margin: 10px 0;
    font-size: 14px;
    text-align: right;
}

.cart-mini-list .cart-mini-info .cart-mini-count {
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin-top: 5px;
    align-items: center;
    margin-bottom: 6px;
}

.cart-mini-list .cart-mini-info .cart-mini-price,
.cart-mini-list .cart-mini-info .cart-mini-properties-item,
.cart-mini-list .cart-mini-info .cart-mini-custom-option {
    font-size: 14px;
    margin-bottom: 5px;
}

.cart-mini-list .cart-mini-info {
    text-align: left;
}

.cart-mini-list .cart-mini-item-col-xs-3 {
    padding-right: 0;
    padding-left: 0;
}

.cart-mini .cart-mini-item-remove-item-block {
    position: relative;
}

.cart-mini .cart-mini-count .cart-mini-amount-control {
    margin-left: 3px;
}

.cart-mini .cart-mini-count .cart-mini-amount-control .spinbox-input-wrap {
    display: none;
}

.cart-mini-item-remove-item {
    display: block;
    position: absolute;
    right: 4px;
    top: 12px;
}

.cart-mini-list .cart-mini-item-remove-item:before {
    content: '';
    background: url(../../../../images/close.png);
    width: 9px;
    height: 9px;
    display: block;
    position: relative;
    right: -3px;
    background-size: 100%;
    top: 0;
    cursor: pointer;
    background-repeat: no-repeat;
}

.cart-mini-info .cart-mini-count .cart-mini-amount-control .spinbox-more:before {
    content: '';
    -webkit-mask-image: url(../../../../images/cart-plus.svg);
    mask-image: url(../../../../images/cart-plus.svg);
    width: 7px;
    height: 7px;
    display: block;
    position: relative;
    right: 0;
    top: 0px;
    cursor: pointer;
    background-color: currentColor;
}

.cart-mini-info .cart-mini-count .cart-mini-amount-control .spinbox-less:before {
    content: '';
    -webkit-mask-image: url(../../../../images/cart-minus.svg);
    mask-image: url(../../../../images/cart-minus.svg);
    width: 7px;
    height: 7px;
    display: block;
    position: relative;
    top: 1px;
    background-color: currentColor;
}


.cart-mini-main {
    display: flex;
    align-items: center;
    font-size: 14px;

    &:hover {
        text-decoration: none;
    }


    .cart-mini-main_cart {
        position: relative;
        margin-right: 4px;

        .cart-mini-main-icon {
            width: 46px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;

            svg {
                fill: currentColor;
            }
        }

        .cart-mini-main-count {
            position: absolute;
            right: 0px;
            top: -2px;
            min-width: 26px;
            padding-left: 3px;
            padding-right: 3px;
            height: 26px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            border: 3px solid white;
            box-sizing: border-box;
        }
    }
}

.cart-full {
    .bonus-card-icon {
        margin-bottom: 21px;
        margin-top: 21px;

        &::before {
            content: '';
            width: 15px;
            height: 21px;
            box-shadow: none;
            mask-image: url(../../../../images/giftcard.svg);
            -webkit-mask-image: url(../../../../images/giftcard.svg);
            vertical-align: bottom;
        }
    }

    .cart-full-bonus-inner {
        padding-right: 10px;
    }

    .cards-form-input-wrap {
        input {
            height: 43px;
        }
    }
}