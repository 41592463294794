.tabs-vertical {
    display: block;

    .tabs-headers {
        display: flex;
        width: initial;
        padding-right: 0;
        margin-bottom: 18px;

        .tabs-header-item {
            margin-right: 32px;
            font-size: 14px;

            a {
                font-size: 14px;
                padding: 8px 10px 7px 10px;
                line-height: 160%;
            }
        }

        .tabs-header-item.selected {
            border-radius: 6px;
            margin-right: 16px;

            a {
                padding: 7px 16px;
            }
        }
    }
}

.tabs-vertical .tabs-content {
    display: block;
}
