.brand-county-name {
    .btn-ghost {
        &::before {
            content: '';
            -webkit-mask-image: url(../../images/search.svg);
            mask-image: url(../../images/search.svg);
            display: block;
            width: 24px;
            height: 24px;
            background-color: currentColor;
        }
    }
}

.brands-input-search + .btn-ghost {
    &::before {
        content: '';
        -webkit-mask-image: url(../../images/search.svg);
        mask-image: url(../../images/search.svg);
        display: block;
        width: 24px;
        height: 24px;
        background-color: currentColor;
    }
}

.brand-txt {
    border-top: 0;

    .link-return {
        margin-bottom: 10px;
    }
}
