.compare-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;

    .compare-header-h1 {
        font-size: 36px;
        line-height: 130%;
    }

    .compare-header-right {
        display: flex;
        align-items: center;

        .compare-header-count {
            margin-right: 14px;
            font-size: 14px;
        }

        .compare-header-remove-all {
            a {
                font-size: 14px;
            }
        }
    }
}

.compareproduct-container {
    padding-right: 0;
    padding-left: 0;

    .compareproduct-product-btns {
        margin-top: 10px;
        text-align: left;
    }

    .compareproduct-products-col {
        .compareproduct-product-item {
            width: 260px;
            min-width: 260px;
            vertical-align: initial;
            text-align: left;
            padding-left: .9375rem !important;

            .compareproduct-product-name {
                text-align: left;
                height: 39px;
                overflow: hidden;
                min-height: 39px;
                margin-top: 25px;

                a {
                    font-size: 16px;
                }
            }

            .products-view-rating {
                text-align: left;
                margin-top: 0;

                .rating {

                    line-height: 1.5;
                    .rating-item {
                        &:before {
                            width: 12px;
                            height: 12px;
                            display: block;
                            font-size: 73%;
                            margin-right: 0px;
                        }
                    }
                }
            }

            .products-view-price-block {
                /*display: flex;
                align-items: center;
                justify-content: space-between;*/
                margin-bottom: 20px;

                .price-container {
                    text-align: left;

                    .price-current, .price-new {
                        font-size: 24px;
                    }
                }

            }
        }
    }
}

.compareproduct-product-item, .compareproduct-properties-item, .compareproduct-product, .compareproduct-properties {
    border: none;
}

.compareproduct-properties-col {
    .compareproduct-properties-row {
        &:not([data-row-index="0"]) {

            .compareproduct-properties-item {
                padding: 15px 0 15px 0;
                font-size: 14px;
            }
        }

        /*&[data-row-index="1"] {

            .compareproduct-properties-item {
                padding-top: 30px;
            }
        }*/
    }

    .compareproduct-properties-row[data-row-index="0"] {
        .compareproduct-properties-item {
            font-size: 18px;
            vertical-align: text-bottom;
            padding-left: 0;
        }
    }


}

.compareproduct-product-row[data-row-index="0"].compareproduct-block-item-hover,
.compareproduct-properties-row[data-row-index="0"].compareproduct-block-item-hover {
    background-color: transparent;
}

.compareproduct-products-col {


    .compareproduct-product-row {
        &:not([data-row-index="0"]) {

            .compareproduct-product-item {
                font-size: 14px;
                padding: 15px 0 15px 0;
            }
            /*&:last-child {

                .compareproduct-product-item {
                    padding-bottom: 25px;
                }
            }*/
        }
    }
}

.product-view-available {
    font-size: 12px;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 10px;
    text-align: left;
}

.product-view-noAvailable {
    font-size: 12px;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 10px;
    text-align: left;
}

.compareproduct-product-remove {
    top: 15px;
    right: 18px;
    width: 14px;
    height: 18px;

    &:before {
        content: '';
        background-repeat: no-repeat;
        display: block;
        width: 14px;
        height: 18px;
        -webkit-mask-image: url(../../images/delete.svg);
        mask-image: url(../../images/delete.svg);
    }
}