.preorder-page {
    .preorder-page-product-amount {
        .spinbox-input-wrap {
            padding-right: 10px;
        }

        .spinbox-input {
            padding-right: 40px;
        }

        .spinbox__arrows {
            right: 21px;
        }
    }
}
