.site-footer-wrap {
    .site-footer-top-level {

        .site-footer-top-level-inner {
            padding-top: 93px;
            padding-bottom: 45px;

            .footer-menu-head {
                font-size: 14px;
            }

            .footer-menu-list {
                .footer-menu-link {
                    font-size: 14px;
                    text-decoration: none;
                }
            }
        }
    }
}

.footer-gifts__wrapper {
    width: 263px;

    .footer-gifts {
        .gift-block {
            border-radius: 4px;
            display: block;
            margin-top: 0;
            padding: 17px 22px 19px 22px;
            font-size: 18px;
            text-transform: uppercase;
            background: #E74343;
            color: #fff;

            .gift-picture {
                margin-bottom: 14px;
                box-shadow: none;
                background: none;

                &:before {
                    content: "";
                    background: url(../../images/gift.svg);
                    background-repeat: no-repeat;
                    width: 47px;
                    height: 49px;
                    background-size: 100%;
                    margin-right: 0;
                }
            }
        }
    }
}

.footer-bottom-level {

    .copyright-block {
        padding-top: 10px;
        padding-bottom: 50px;
    }

    .footer-bottom-level-inner {
        padding-top: 0;
        padding-bottom: 0;
        .copyright-block {
            font-size: 14px;
            flex: 27% 1 1;

            a {
                font-size: 14px;
            }
        }

        .RightBottom-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 73% 1 1;
        }

        .footer-links {
            a {
                font-size: 14px;

                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }
}
