.checkout-title {
    .main-title {
        font-size: 36px;
    }
}

.checkout-block {
    border: none;

    .checkout-block-title {
        font-size: 24px;
    }

    .link-dotted-invert[data-zone-dialog-trigger] {
        border-bottom-style: none;
        font-size: 14px;
        border: none;
        padding-left: 15px;
        font-size: 14px;

        [data-zone-current] {
            padding-left: 20px;
            position: relative;
            font-size: 14px;

            &:before {
                content: '';
                -webkit-mask-image: url(../../images/Union.svg);
                mask-image: url(../../images/Union.svg);
                background-repeat: no-repeat;
                width: 8px;
                height: 14px;
                display: block;
                background-position: center;
                position: absolute;
                left: 0;
                top: -1px;
            }
        }
    }

    .shipping-list .shipping-item,
    .payment-list .payment-item {
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        align-items: flex-start;
        padding: 12px;
        padding-left: 0;
        margin-bottom: 4px;

        .custom-input-radio {
            height: 24px;
            width: 24px;
            box-shadow: none;
            margin-right: 12px;
            margin-top: 3px;

            &:after {
                width: 10px;
                height: 10px;
            }
        }


        .btn {
            margin-top: 5px;
        }
    }

    .shipping-list .shipping-item-icon,
    .payment-list .payment-item-icon {
        padding-bottom: 0;
        padding-right: 10px;
        line-height: 0;
    }

    .shipping-item-radio, .payment-item-radio {
        margin-top: 0;
    }

    .shipping-item .shipping-item-info, .payment-item .payment-item-info {
        padding: 0;

        .form-field-name {
            line-height: normal;
            font-size: 14px;
        }
    }


    .shipping-item-info {
        .shipping-item-title {
            padding-bottom: 5px;
        }

        .shipping-item-description {
            padding-bottom: 0;
        }
    }

    .payment-item-info {
        .payment-item-title {
            padding-bottom: 5px;
        }

        .payment-item-description {
            padding-bottom: 0;
        }
    }


    .checkout-block-content {
        textarea {
            box-sizing: border-box;
            border-radius: 4px;
            font-size: 15px;
            padding: 16px 20px;
            line-height: 140%;
        }
    }

    .checkout-new-customer-form {
        .form-field-name-wrap,
        .form-field-input-wrap {
            padding-left: 0;
        }
    }

    [data-cards-form] {
        .input-small {
            height: 43px;
        }

        .checkout-gift-button {
            .btn-expander {
                border-radius: 4px;
                font-weight: normal;
            }
        }
    }

    [data-address-list] {

        .address-list-change-text {
            color: black;
            display: flex;
            align-items: center;
        }

        .address-list-item {
            border: none;
        }
    }

    .bonus-card-icon-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.checkout-result {
    .checkout-result-price {
        font-weight: 500;
    }
}

.checkout__button-summary {
    border-radius: 4px;
    font-weight: normal;
}


#rightCell {
    .checkout-cart-wrap {
        .checkout-cart {
            .form-title {
                padding-top: 26px;
                padding-left: 32px;
                padding-right: 39px;
                font-size: 16px;
                padding-bottom: 3px;
            }

            .checkout-cart-content {
                .checkout-cart-items {
                    padding-left: 32px;
                    padding-right: 39px;

                    .checkout-cart-item-row {
                        .checkout-cart-item-cell-name {
                            padding-left: 0;

                            a {
                                font-size: 14px;
                            }

                            .checkout-cart-item-count {
                                font-size: 14px;
                            }

                            .checkout-cart-item-properties {
                                padding-top: 0.4rem;

                                .cart-full-properties-item {
                                    .cart-full-properties-name, .cart-full-properties-value {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }

                        .checkout-cart-item-cell-value {
                            padding-right: 0;

                            .checkout-cart-price {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}


.checkout-cart-item-cell {
    vertical-align: initial;
}

.checkout-cart-generated-field {
    padding-left: 32px;
    padding-right: 39px;
    border: none;
    padding-top: 0;
    padding-bottom: 0;

    .checkout-cart-generated-cell {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom-width: 0.0625rem;
        border-bottom-style: solid;

        .checkout-cart-generated-field-text {
            font-size: 14px;
        }

        .checkout-cart-generated-field-price {
            font-size: 14px;
        }
    }
}

.checkout-cart-result {
    background: none;
    padding-left: 32px;
    padding-right: 39px;

    .checkout-result-text {
        font-size: 14px;
    }

    .checkout-cart-result-price {
        font-size: 14px;
        padding-right: 0.625rem;
        font-weight: normal;
    }
}

.checkout-cart-verify {
    padding-left: 32px;
    padding-right: 39px;
    padding-bottom: 18px;
    padding-top: 18px;

    a {
        font-size: 12px;
    }
}

.form-field-control {
    .form-field-name-wrap,
    .form-field-input-wrap {
        flex-basis: 100%;
        max-width: 100%;
    }

    .checkout-bonus-card-checkbox-wrap {
        margin-left: 0;
        flex-basis: 100%;
        max-width: 100%;

        .bonus-card-icon {
            &::before {
                content: '';
                width: 15px;
                height: 21px;
                box-shadow: none;
                mask-image: url('../../images/giftcard.svg');
                -webkit-mask-image: url('../../images/giftcard.svg');
            }
        }
    }
}

.checkout-agree {
    .checkout-agree-label > .custom-input-checkbox {
        top: -4px;
    }

    .checkout-agree-label > .custom-input-text__indent {
        padding-left: 30px;
    }
}

.checkout-thank-you-page {
    &__social-link {
        &:hover {
            text-decoration: none;
        }
    }
}
