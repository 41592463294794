.managers-email-dialog {
    .vertical-interval-middle {
        margin-top: 0;
        margin-bottom: 7px;
    }
}

.managers-call-dialog {
    .vertical-interval-middle {
        margin-top: 0;
        margin-bottom: 7px;
    }
}